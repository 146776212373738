import React from "react"

import Layout from "../components/layout"
import ShopHeader from "../components/shop-header"

const ShopLayout = ({ children }) => {
  return (
    <Layout>
      <ShopHeader />
      {children}
    </Layout>
  )
}

export default ShopLayout
