import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import SEO from "../components/seo"
import ShopLayout from "../templates/shop-layout"
import ProductForm from "../components/product-form"

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  return (
    <ShopLayout>
      <SEO
        title={`Yams Studio: ${product.title}`}
        meta={[
          {
            property: `og:image`,
            content: `${product.images[0].originalSrc}`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:image`,
            content: `${product.images[0].originalSrc}`,
          },
        ]}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-5 my-12">
        <div className="grid grid-cols-1 gap-4">
          {product.images.map(image => {
            if (image.localFile.childImageSharp) {
              return (
                <Image
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={product.title}
                  key={image.id}
                />
              )
            } else {
              return (
                <img
                  src={image.originalSrc}
                  alt={product.title}
                  className="w-50"
                  key={image.id}
                />
              )
            }
          })}
        </div>
        <div>
          <h1 className="text-xl md:text-2xl leading-none">{product.title}</h1>
          <ProductForm product={product} />
          <div
            dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            className="mt-4"
          />
        </div>
      </div>
    </ShopLayout>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default ProductPage
