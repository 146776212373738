import React, { useContext } from "react"
import { Link } from "gatsby"

import { ShopContext } from "../context/shop-context"

const ShopHeader = () => {
  const { checkout } = useContext(ShopContext)

  const quantityTotal = () => {
    if (checkout?.lineItems.length >= 1) {
      return checkout?.lineItems.reduce((acc, item) => acc + item.quantity, 0)
    }
    return 0
  }

  return (
    <div className="flex justify-between p-5 sticky top-0 bg-white z-1">
      <div>
        <Link
          to="/shop"
          className="md:text-xl hover:text-blue-500 transition duration-150 ease-in-out"
        >
          Yams Studio Shop
        </Link>
      </div>
      <div>
        <Link to="/cart" className="transition duration-150 ease-in-out">
          Cart ({quantityTotal()})
        </Link>
      </div>
    </div>
  )
}

export default ShopHeader
