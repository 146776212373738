import React from "react"
import PropTypes from "prop-types"

const VariantSelector = props => {
  const { option } = props
  return (
    <div className="text-lg flex items-center">
      <div className="selector relative">
      <label htmlFor={option.name} className="mr-2">
        {option.name}
      </label>
      <select
        name={option.name}
        key={option.id}
        onChange={props.onChange}
        className="focus:outline-none focus:shadow-outline transition duration-150 ease-in-out border border-gray-300 appearance-none pl-2 px-1 pr-6 relative rounded-none bg-white"
      >
        {option.values.map(value => {
          return (
            <option
              value={value}
              key={`${option.name}-${value}`}
            >{`${value}`}</option>
          )
        })}
      </select>
      </div>
    </div>
  )
}

VariantSelector.propTypes = {
  onChange: PropTypes.func,
  option: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default VariantSelector
